@import '../../lib/variables.scss';

.title {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

.errorMessage {
    font-size: 1rem;
    height: 1rem;
    margin: .5rem 0 1rem 0;
    color: $red;
}
.messageText{
    @extend  .errorMessage;
    color: $dark_grey;
}

.securityNumberContainer {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    border: 1px solid #cccccc;
    padding-left: 4px;
    margin-bottom:2rem;
}
.secNumInitials {
    font-size: 20px;
    margin: 0;
}
.securityNumberInput {
    font-size: 19px;
    width: 90%;
    outline: none;
    padding: 0.3rem 0px;
    border: 0px;
}
.securityNumberInput::placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
.securityNumberInput::-webkit-input-placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
.securityNumberInput:-moz-placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
.securityNumberInput::-moz-placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
.securityNumberInput:-ms-input-placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
.securityNumberInput::-ms-input-placeholder {
    font-size: 1.5rem;
    color: #e1e2e2;
}
