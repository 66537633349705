.header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  column-gap: 20px;
  color: rgb(218, 58, 58);
}

.heading {
  padding: 0;
  margin: 0;
  text-align: center;
}

.body {
  color: #333333;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
}

.closeButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}

.closeButton {
  border: 0;
  border-radius: 3rem;
  font-weight: bold;
  background: red;
  color: white;
  padding: 2px 2px 3px;
  width: 150px;
  height: 40px;
}

