.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 2rem 0 2rem;
}

.errorCode {
    font-size: 4rem;
}