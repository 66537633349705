@import '../../lib/variables.scss';
.content_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.checkin_instruction_text{
  color:$light_text;
}
