@import '../../lib/variables.scss';

.securityNumber {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark_grey;
}

.inputContainer {
    display: flex;
    gap: 0.6rem;
    margin-top: 1rem;
}

.securityNumberWrapper {
    max-width: 4rem;
    height: 4rem;
}

.securityNumberInput {
    width: 100%;
    height: 100%;
    text-align: center;
    border: 1px solid #dfdfdf;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    outline: none;
    background-clip: padding-box;
    &::placeholder {
        background: -webkit-linear-gradient(#2794dc, #22bbc4);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bolder;
    }
    user-select: none;
}
.securityNumberInputFilled{
    @extend .securityNumberInput;
    border: 1px solid #22BBC4;

}

.errorColor {
    border-color: #e31c40;
    color: #e31c40;
}

.errorMessage {
    font-size: 1rem;
    height: 1rem;
    margin: 0;
}
