@import '../../lib/variables.scss';

.surveyInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 3rem;
    width: 80%;
    @media only screen and (min-width: 768px) {
        margin-top: 4rem;
        gap: 1rem;
    }
}
.checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    padding: 5px 20px;
}
.surveyButton {
    padding: 1rem 1.5rem;
    align-self: center;
    border: 0;
    margin-top: 3rem;
    border-radius: 3rem;
    font-weight: bold;
    font-size: 1.2rem;
    background: $blue_to_green_gradient;
    color: white;
    width: 60%;
    &:disabled {
        background: $light_grey;
    }
    &:active {
        background: $transparent_blue_to_green_gradient;
    }
    @media only screen and (min-width: 768px) {
        font-size: 1.8rem;
        margin-top: 10rem;
        padding: 1.3rem 2rem;
    }
}
