.bg {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    backdrop-filter: blur(1.5px);
}
.modal {
    position: fixed;
    top: 20%;
    left: 30%;
    width: 60%;
}
.modalContent {
    background-color: white;
    opacity: 1;
    z-index: 1000;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    padding: 20px 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 67%;
    row-gap: 30px;
    border-radius: 10px;
}

@media screen and (max-width: 428px) {
    .modal {
        position: fixed;
        top: 40%;
        left: 60%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
    .modalContent {
        width: 80%;
        padding: 0 20px 20px;
    }
}
