@import '../../lib/variables.scss';

.surveyNumberInput {
    font-size: 1rem;
    width: 100%;
    outline: none;
    padding: 0.3rem;
    border-radius: 0.4rem;
    border: 2px solid $light_grey;
    margin-top: 2px;
    @media only screen and (min-width: 768px) {
        font-size: 1.3rem;
    }
}
.label_text {
    color: $dark_grey;
}
.surveyInputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    @media only screen and (min-width: 768px) {
        font-size: 1rem;
    }
}
