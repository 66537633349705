@import '../../lib/variables.scss';

.wrapper {
    height: 100vh;
    background: linear-gradient(90.33deg, #2794dc 0%, #22bbc4 94.92%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    min-width: 300px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #e5e5e5;
    border-radius: 40px;
    padding: 80px 100px;
    position: relative;
}

.formField {
    width: 100%;
    margin-bottom: 16px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    font-size: 18px;
    padding: 16px;
    color: rgba(51, 51, 51, 0.5);
    background-clip: padding-box;

    &::placeholder {
        color: rgba(51, 51, 51, 0.5);
    }
}

.formLabel {
    width: 100%;
    text-align: left;
    margin-bottom: 4px;
}

.submitButton {
    width: 100%;
    background: linear-gradient(90.33deg, #2794dc 0%, #22bbc4 94.92%);
    border-radius: 0.5rem;
    color: #ffffff;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
    padding: 16px;
    text-transform: uppercase;
    border: none;

    &:disabled {
        background: $light_grey;
        border: none;
    }
}

.error {
    font-size: 18px;
    position: absolute;
    bottom: 25px;
    color: red;
}
