@import '../../lib/variables.scss';

.wrapper {
    height: calc(100vh - 8rem);
    background: $blue_to_green_gradient;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.surveyHeaderText {
    margin: 2rem 0 0 0;
    font-size: 2.5rem;
    font-weight:700;
    color: #ffffff;
    text-transform: uppercase;
    @media only screen and (min-width: 768px) {
        font-size: 2.7rem;
        margin: 1rem 0 0 0;
    }
}

.surveyMessageText {
    margin: 1rem 2rem 0 2rem;
    line-height: 2rem;
    font-size: 1rem;
    text-align: center;
    color: #ffffff;
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
        margin: 1rem 0 0 0;
    }
}
.surveyStepsText {
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
}

.surveyIconsRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
    margin-top: 2rem;
    background: rgba(248, 248, 248, 0.1);
    border-radius: 1.1rem;
    max-width: 90%;
}
.iconsContainer{
    display: flex;
    gap: 50px;
}
.surveyIconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    color: #ffffff;
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
}

.surveyIcon {
    height: 9rem;
    width: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.surveySuccessfulNewCheckInButton {
    padding: 1rem 1.7rem;
    margin-top: 3rem;
    border-radius: 3rem;
    align-self: center;
    font-weight: bold;
    font-size: 1rem;
    background: #ffffff;
    border: 0;
    color: black;
    &:active {
        opacity: 50%;
    }
    @media only screen and (min-width: 768px) {
        padding: 1rem 1.7rem;
        border-radius: 3rem;
        font-size: 1.5rem;
    }
}

.surveyIconImage {
    height: 70%;
    width: 70%;
}
