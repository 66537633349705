@import '../../lib/variables.scss';

.clock {
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    display: flex;
    height: 4.5rem;
    font-weight: 500;
    font-size: 1.6rem;
    gap: 0.3rem;
    padding: 0 1.5rem;
    border-radius: 3rem;
    align-items: center;
    user-select: none;
    border: 1px solid rgba(255, 255, 255, 0.4);
}
