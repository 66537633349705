.triangle {
  &,
  &::before,
  &::after {
    width: 80px;
    height: 80px;
  }

  position: relative;
  overflow: hidden;
  transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(0.866);
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background: #91c9ed;
    pointer-events: auto;
  }

  &::before {
    // prettier-ignore
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
  }

  &::after {
    // prettier-ignore
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
  }

  border-radius: 20%;

  &::before {
    border-radius: 20% 20% 20% 55%;
  }

  &::after {
    border-radius: 20% 20% 55% 20%;
  }
}

.triangle_sm {
  @extend .triangle;

  &,
  &::before,
  &::after {
    width: 40px;
    height: 40px;
  }

}

.exclamationMark {
  position: absolute;
  color: #2794DC;
  font-size: 40px;
  font-weight: bold;
}

.exclamationMark_sm {
  @extend .exclamationMark;
  font-size: 20px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  column-gap: 20px;
  color: rgb(218, 58, 58);
}
