@import './font';

// Colors
$blue_to_green_gradient: linear-gradient(90.33deg, #2794dc 0%, #22bbc4 94.92%);
$transparent_blue_to_green_gradient: linear-gradient(
    90.33deg,
    rgba(#2794dc, 0.5) 0%,
    rgba(#22bbc4, 0.5) 94.92%
);
$transparent_white: rgba(#ffffff, 0.2);
$grey:#D9D9D9;
$dark_grey: #000000;
$light_grey: #e5e5e5;
$light_text: rgba(0, 0, 0, 0.6);
$bg_color: #F8F8F8;
$green: #22C493;
$blue: #2794dc;
$off_white: rgba(#ffffff, 0.8);
$dark_green: #068e06;
$red: #E31C40;
$light_red:rgba(227, 28, 64, 0.1);
