@import '../../lib/variables.scss';

.wrapper {
    height: 5.5rem;
    display: flex;
    align-items: center;
    border-bottom: 4px solid;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    padding: 0 2rem 0 2rem;
    border-image-source: $blue_to_green_gradient;
    border-image-slice: 1;
}


.logo {
    margin-left: 2rem;
    max-height: 70%;
}