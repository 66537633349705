@import '../../lib/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 5.5rem);
    justify-content: flex-start;
    padding-top: 50px;
}
.container_gradient_bg {
    @extend .container;
    background: $blue_to_green_gradient;
}
