@import '../../lib/variables.scss';

.container {
    display: flex;
    justify-content: flex-start;
    column-gap: 5%;
    align-items: flex-start;
    width: 100%;
    border: 1.3px solid $green;
    border-radius: 10px;
    cursor: pointer;
    padding: 18px;
}
.container p {
    font-size: 1.1rem;
    @media screen and (min-width: 786px) {
        font-size: 1.3rem;
    }
}

.container_disabled {
    @extend .container;
    border: 1px solid $grey;
    cursor: default;
}

.booking_container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 90%;
}

.button {
    max-width: 110px;
    width: auto;
    border: 1px solid $green;
    border-radius: 30px;
    padding: 5px;
    text-align: center;
    color: $green;
}

.button_blue {
    @extend .button;
    border: 1px solid $blue;
    color: $blue;
}
.button p {
    font-size: 16px;
}
.checkInButton {
    width: 40%;
    border-radius: 50px;
    border: none;
    background: $blue_to_green_gradient;
    color: #ffffff;
    padding: 10px 5px;
    font-size: 1em;
    font-weight: 600;
}

.information_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 6px;
}

.title {
    font-weight: 600;
}
.arrow {
    width: 10%;
}
