@import '../../lib/variables.scss';

.payment_info_text {
  padding: 10px;
  border-radius: 5px;
  background-color: #d7f1f2;
}

.check_in_successful_header {
  text-align: start;
}

.payment_method_list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bold_text{
  font-weight: 600;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_method_container {
  width: 100%;
}

.radio_logo_container {
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
  width: 95%;
  padding-bottom: 10px;
}

.text_container {
  width: 100%;
  padding-top: 10px;
}

.radio_button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}

.button_container {
  display: flex;
  row-gap: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.success_text_container {
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  width: 90%;
}

.success_text {
  font-size: 1.2rem;
  color: $dark_green;
}
