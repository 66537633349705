@import "../../lib/variables";

.button {
  padding: 16px 26px;
  border: 0;
  border-radius: 50px;
  width:auto;
  font-size: 1rem;
  background: $blue_to_green_gradient;
  color: white;
  user-select: none;
  &:disabled {
    background: $light_grey;
  }
  @media  screen and (min-width: 768px) {
    padding: 25px 36px;
    font-size: 1.5rem;
    font-weight: 500;
  }
}
.transparent{
  @extend .button;
  background: transparent;
  border:1px solid $grey;
  color: #444444;
}
