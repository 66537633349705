@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

$font_default: 14px;
$font_body1: 18px;
$font_h1: 2rem;
$font_h2: 1.5rem;
$font_h4: 1.2rem;

// Font family
$font_family: 'Inter', sans-serif;
